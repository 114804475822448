<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Final Approval</h2>
    </template>
    <template #page-content>
      <div>
        <div class="final-approval-container">
          <p class="final-approval-title">Request Overtime</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="overtimeName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredOvertime"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isOvertimeSubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isOvertimeSubmitting"
              @click="changeOvertimeUser"
              :disabled="!overtimeName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Request Time Off</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="timeOffName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredTimeoff"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isTimeOffSubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isTimeOffSubmitting"
              @click="changeTimeOffUser"
              :disabled="!timeOffName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Request Change Schedule</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="changeScheduleName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredChangeSchedule"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isChangeScheduleSubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isChangeScheduleSubmitting"
              @click="changeChangeScheduleUser"
              :disabled="!changeScheduleName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Request Reimbursement</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="reimbursementName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredReimbursement"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isReimbursementSubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isReimbursementSubmitting"
              @click="changeReimbursementUser"
              :disabled="!reimbursementName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Request Log Activity</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="logActivityName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredLogActivity"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isLogActivitySubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isLogActivitySubmitting"
              @click="changeLogActivityUser"
              :disabled="!logActivityName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Request WFO Permission</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="wfoPermissionName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredWFOPermission"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isWFOPermissionSubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isWFOPermissionSubmitting"
              @click="changeWFOPermissionUser"
              :disabled="!wfoPermissionName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Personal Info Permission</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="personalInfoPermissionName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredPersonalInfoPermission"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isPersonalInfoPermissionSubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isPersonalInfoPermissionSubmitting"
              @click="changePersonalInfoPermissionUser"
              :disabled="!personalInfoPermissionName"
            >
              Save
            </b-button>
          </div>
        </div>

        <div class="final-approval-container">
          <p class="final-approval-title">Request Attendance Activity</p>
          <div class="is-flex is-align-items-center">
            <b-autocomplete
              v-model="attendanceActivityName"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredAttedanceActivity"
              class="final-approval-autocomplete"
              @select="(option) => (selected = option)"
              :loading="isAttendanceActivitySubmitting"
              clearable
            ></b-autocomplete>
            <b-button
              class="is-primary"
              :loading="isAttendanceActivitySubmitting"
              @click="changeAttendanceActivity"
              :disabled="!attendanceActivityName"
            >
              Save
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/services/util'

export default {
  data() {
    return {
      isFetchingUserListLoading: false,
      isFetchingApprovalListLoading: false,
      isOvertimeSubmitting: false,
      isTimeOffSubmitting: false,
      isChangeScheduleSubmitting: false,
      isReimbursementSubmitting: false,
      isLogActivitySubmitting: false,
      isWFOPermissionSubmitting: false,
      isPersonalInfoPermissionSubmitting: false,
      isAttendanceActivitySubmitting: false,

      overtimeName: null,
      timeOffName: null,
      changeScheduleName: null,
      reimbursementName: null,
      logActivityName: null,
      wfoPermissionName: null,

      personalInfoPermissionName: null,
      attendanceActivityName: null,

      userNameLists: [],
    }
  },
  async mounted() {
    await this.loadUserList()
    await this.loadFinallApprovalList()

    await this.finalApprovalList.forEach((el) => {
      switch (+el.moduleType) {
        case 1:
          this.overtimeName =
            el.userName && `${el.userName} (${el.positionName})`
          break

        case 2:
          this.timeOffName =
            el.userName && `${el.userName} (${el.positionName})`
          break

        case 3:
          this.changeScheduleName =
            el.userName && `${el.userName} (${el.positionName})`
          break

        case 4:
          this.reimbursementName =
            el.userName && `${el.userName} (${el.positionName})`
          break

        case 5:
          this.logActivityName =
            el.userName && `${el.userName} (${el.positionName})`
          break

        case 6:
          this.wfoPermissionName =
            el.userName && `${el.userName} (${el.positionName})`
          break
        case 7:
          this.personalInfoPermissionName =
            el.userName && `${el.userName} (${el.positionName})`
          break
        case 8:
          this.attendanceActivityName =
            el.userName && `${el.userName} (${el.positionName})`
          break
      }
    })

    this.userNameLists = this.userLists.map(
      (el) => `${el.name} (${el.positionName})`
    )
  },
  computed: {
    ...mapGetters({
      userLists: 'finalApproval/getUserLists',
      finalApprovalList: 'finalApproval/getFinalApprovalLists',
    }),
    filteredOvertime() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.overtimeName?.toLowerCase()) >= 0
        )
      })
    },
    filteredTimeoff() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.timeOffName?.toLowerCase()) >= 0
        )
      })
    },
    filteredChangeSchedule() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.changeScheduleName?.toLowerCase()) >= 0
        )
      })
    },
    filteredReimbursement() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.reimbursementName?.toLowerCase()) >= 0
        )
      })
    },
    filteredLogActivity() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.logActivityName?.toLowerCase()) >= 0
        )
      })
    },
    filteredWFOPermission() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.wfoPermissionName?.toLowerCase()) >= 0
        )
      })
    },

    filteredPersonalInfoPermission() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.personalInfoPermissionName?.toLowerCase()) >= 0
        )
      })
    },
    filteredAttedanceActivity() {
      return this.userNameLists.filter((option) => {
        return (
          option
            ?.toString()
            ?.toLowerCase()
            .indexOf(this.attendanceActivityName?.toLowerCase()) >= 0
        )
      })
    },
  },
  methods: {
    ...mapActions({
      actionUpdateFinalApprovalUser: 'finalApproval/updateFinalApprovalUser',
      actionFetchUserLists: 'finalApproval/fetchUserLists',
      actionFetchFinalApprovalLists:
        'finalApproval/fetchExistingFinalApprovalLists',
    }),

    async loadUserList() {
      this.isFetchingUserListLoading = true

      try {
        await this.actionFetchUserLists()
      } catch (err) {
        console.log(err)
      }
      this.isFetchingUserListLoading = false
    },

    async loadFinallApprovalList() {
      this.isFetchingApprovalListLoading = true

      try {
        await this.actionFetchFinalApprovalLists()
      } catch (err) {
        console.log(err)
      }
      this.isFetchingApprovalListLoading = false
    },

    async changeOvertimeUser() {
      this.isOvertimeSubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.overtimeName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 1)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isOvertimeSubmitting = false
    },

    async changeTimeOffUser() {
      this.isTimeOffSubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.timeOffName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 2)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isTimeOffSubmitting = false
    },

    async changeChangeScheduleUser() {
      this.isChangeScheduleSubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.changeScheduleName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 3)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isChangeScheduleSubmitting = false
    },

    async changeReimbursementUser() {
      this.isReimbursementSubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.reimbursementName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 4)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isReimbursementSubmitting = false
    },

    async changeLogActivityUser() {
      this.isLogActivitySubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.logActivityName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 5)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isLogActivitySubmitting = false
    },

    async changeWFOPermissionUser() {
      this.isWFOPermissionSubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.wfoPermissionName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 6)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isWFOPermissionSubmitting = false
    },

    async changePersonalInfoPermissionUser() {
      this.isPersonalInfoPermissionSubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.personalInfoPermissionName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 7)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isPersonalInfoPermissionSubmitting = false
    },

    async changeAttendanceActivity() {
      this.isAttendanceActivitySubmitting = true
      let form = new FormData()
      const selectedUser = this.userLists.filter(
        (el) => this.attendanceActivityName.indexOf(el.name) >= 0
      )
      const userId = selectedUser[0].id

      try {
        form.append('user_id', userId)
        form.append('module_type', 8)

        await this.actionUpdateFinalApprovalUser(form)
        showToast('Data is successfully updated!', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isAttendanceActivitySubmitting = false
    },
  },
}
</script>
